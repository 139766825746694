.main {
  padding: 10px 0;
  margin-bottom: 20px;
  .value {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
  }
}
.account-info-tab {
  margin-top: 10px;
  .tab-container {
    border-radius: 10px;
    background: #eeeff0;
    display: flex;
    flex-wrap: wrap;
  }
  .tab-row {
    padding: 20px 40px 20px 20px;
    .title {
      font-size: 16px;
      padding-bottom: 10px;
    }
    .value {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
