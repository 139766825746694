.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  .sub-nav-container {
    .sub-nav-list {
      font-size: 14px;
    }
    .active {
      color: #0778f6;
      pointer-events: none;
    }
  }
}
