.digit-group {
    input {
        width: 30px;
        height: 50px;
        border: none;
        border-bottom: 1px solid #535e74;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        font-family: "Raleway", sans-serif;
        font-weight: 200;
        margin: 0 5px;
        outline: none;
    }
    .splitter {
        padding: 0 5px;
        font-size: 24px;
    }
}

.error {
    color: #ff0000;
    text-align: left;
}

.login__otp {
    // max-width: 300px;
    // margin-left: auto;
    // margin-left: auto;
}