.container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    .icon {
        font-size: 50px;
        background-color: #ddd;
        padding: 15px;
        border-radius: 50%;
    }
}
