.nav-container {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    .nav-list {
        float: left;
        list-style: none;
        height: 100%;
        padding: 0 20px;
        a {
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            height: 100%;
            margin: 0;
            position: relative;
        }
        &.active a {
            color: #0778f6;
        }
        &.active a:after {
            content: " ";
            width: 100%;
            position: absolute;
            bottom: 0;
            border-bottom: 3px solid #0778f6;
        }
    }
    .sub-nav-container {
        position: absolute;
        list-style: none;
        margin: 0;
        padding: 10px 0;
        left: 24px;
        .sub-nav-list {
            float: left;
            margin-right: 20px;
            font-size: 12px;
            cursor: pointer;
        }
        .active {
            color: #0778f6;
        }
    }
}
