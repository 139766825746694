.main {
  // background: #f5f5f5;
  // padding: 20px 50px;
  margin-top: 50px;
  // max-width: 750px;
  // border-radius: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  .title {
    margin: 0 0 10px 0;
    display: inline-block;
  }
  .value {
    font-weight: 600;
  }
  .link {
    color: #0778f6;
  }
}
