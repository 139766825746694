.loader {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  // position: fixed;
  background: #fff;
  top: 0;
  left: 0;
}
