.menuItem {
  .miTitle {
    font-size: 14px;
  }
  .miValue {
    font-weight: 600;
  }
}

.main {
  .balance {
    padding: 4px 4px;
    .title {
      margin: 0 0 4px 0;
    }
    .value {
      font-weight: 600;
    }
  }
  .dataTable {
    height: 400px;
    width: 100%;
  }
}
